import React, { FC, useState, useEffect } from 'react';
import './Articles.scss';
import articles from '../../assets/static/articles.json';
import { Article } from '../../data/data';
import { getRandomItem } from '../../data/helper';

interface ArticlesProps {}

const Articles: FC<ArticlesProps> = () => {
  const words: Article[] = articles;
  const [word, setWord] = useState<Article>();
  const [answer, setAnswer] = useState<string>("test");

  useEffect(() => {
    setWord(getRandomItem(words));
  }, [words]);

  const handleAnswer = (a: string) => {
    setAnswer(a);
    if (a === word?.article) {
      setTimeout(() => {
        handleNew();
      }, 500);
    }
  };

  const handleNew = () => {
    setAnswer("test");
    setWord(getRandomItem(words));
  };

  return (
    <div className="Articles">
      <div className='article-container'>
        <h1 className='fs-2'>Choose the Correct Article</h1>
        <p className='article-word fs-3'>{word?.word}</p>
        <div className='article-button-container'>
          <button 
            onClick={() => handleAnswer("")}
            className=
              {
                `btn fs-5 ${
                  answer === ""
                    ? answer === word?.article
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`
              }
          >
            -
          </button>
          <button 
            onClick={() => handleAnswer("a")}
            className=
              {
                `btn fs-5 ${
                  answer === "a"
                    ? answer === word?.article
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`
              }
          >
            a
          </button>
          <button 
            onClick={() => handleAnswer("an")}
            className=
              {
                `btn fs-5 ${
                  answer === "an"
                    ? answer === word?.article
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`
              }
          >
            an
          </button>
          <button 
            onClick={() => handleAnswer("the")}
            className=
              {
                `btn fs-5 ${
                  answer === "the"
                    ? answer === word?.article
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`
              }
          >
            the
          </button>
        </div>
        <div className='about-articles'>
          <h1>How to Use Articles (a/an/the)</h1>
          <div className="">
              <h2>1. Indefinite Articles ("a" and "an")</h2>
              <p>Used for singular, countable nouns when referring to something general or unknown.</p>
              <ul>
                  <li><strong>"A"</strong> before words with a consonant sound: <em>a cat, a book, a university</em></li>
                  <li><strong>"An"</strong> before words with a vowel sound: <em>an apple, an hour, an elephant</em></li>
              </ul>
              <div className="example">Example: <strong>I saw a dog</strong> (not a specific dog).</div>
          </div>

          <div className="box">
              <h2>2. Definite Article ("the")</h2>
              <p>Used for specific things, unique objects, or something already mentioned.</p>
              <ul>
                  <li>When referring to something previously mentioned: <em>The dog was big.</em></li>
                  <li>Unique things: <em>the sun, the moon, the internet</em></li>
                  <li>Superlatives: <em>the best, the first</em></li>
                  <li>Geographical names: <em>the United States, the Alps</em></li>
              </ul>
              <div className="example">Example: <strong>The Eiffel Tower is in Paris.</strong></div>
          </div>

          <div className="box">
              <h2>3. No Article (Zero Article)</h2>
              <p>Used with general ideas, proper nouns, and uncountable nouns.</p>
              <ul>
                  <li>Plural and uncountable nouns (general meaning): <em>Dogs are friendly. Water is important.</em></li>
                  <li>Names of countries, cities, streets: <em>France, London, Main Street</em></li>
                  <li>Languages, subjects, meals: <em>He speaks Spanish. I study history.</em></li>
              </ul>
              <div className="example">Example: <strong>She loves music.</strong> (Music in general, not specific music)</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Articles;
