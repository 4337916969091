import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './components/Main/Main';
import Menu from './components/Menu/Menu';
import WordMatch from './components/WordMatch/WordMatch';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import IrregularVerbs from './components/IrregularVerbs/IrregularVerbs';
import ContactUs from './components/ContactUs/ContactUs';
import AboutUs from './components/AboutUs/AboutUs';
import HowTo from './components/HowTo/HowTo';
import GeneralTips from './components/GeneralTips/GeneralTips';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import SentenceWriting from './components/SentenceWriting/SentenceWriting';
import Listening from './components/Listening/Listening';
import ListeningTrueFalse from './components/Listening/ListeningTrueFalse';
import Articles from './components/Articles/Articles';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <BrowserRouter>
          <Header></Header>
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/quiz" element={<Main />} />
            <Route path="/words" element={<WordMatch />} />
            <Route path="/irregular" element={<IrregularVerbs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/how-to" element={<HowTo />} />
            <Route path="/tips" element={<GeneralTips />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/writing" element={<SentenceWriting />} />
            <Route path="/listening" element={<Listening />} />
            <Route path="/listening/true-false" element={<ListeningTrueFalse />} />
            <Route path="/articles" element={<Articles />} />
          </Routes>
          <Footer></Footer>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;
