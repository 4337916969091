import React, { FC } from 'react';
import './Header.scss';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import ShareButton from '../ShareButton/ShareButton';

const Header: FC = () => {

  return (
    <div className="header" data-testid="Header">
      <Navbar collapseOnSelect expand="lg" className='nav-header' variant="light">
        <Container className='fs-6'>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/quiz">Quiz</Nav.Link>
              <NavDropdown title="Words" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/words">Vocabulary</NavDropdown.Item>
                <NavDropdown.Item href="/articles">Articles</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/irregular">Irregular Verbs</Nav.Link>
              <NavDropdown title="Listening" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/listening">Listening</NavDropdown.Item>
                <NavDropdown.Item href="/listening/true-false">True/False</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              <NavDropdown title="More" id="collasible-nav-dropdown">
                <NavDropdown.Item href="/how-to">How to use the app?</NavDropdown.Item>
                {/* <NavDropdown.Item href="/formal-informal">Formal vs Informal</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/conditionals">Conditionals</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/tips">General Tips</NavDropdown.Item> */}
                {/* <NavDropdown.Item href="/future">Future Development</NavDropdown.Item> */}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Brand href="/" className='fs-4'>Everyday English</Navbar.Brand>
          <div className=''>
            <ShareButton />
          </div>
        </Container>
      </Navbar>
      <hr className="text-dark" />
    </div>
  );
};


export default Header;
